<template>
    <div class="page">
        <div class="main">
            <div class="service">
                <div class="title">意见反馈</div>
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 4}"
                        placeholder="请输入内容"
                        v-model="text">
                </el-input>
                <div class="title">服务期限</div>
                <div class="content">
                    <span>您于2020年3月4日购买本系统使用，服务期限为30天，剩余20天</span>
                </div>
                <div class="title">联系我们</div>
                <div class="content">
                    <p>联系电话：1300000000</p>
                    <p>技术微信：12334</p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    //time: 2021/2/9
    export default {
        name: "service",
        data() {
            return {
                text:''
            }
        },
        created() {
            
        },
        methods: {
            
        }
    }

</script>

<style lang="less" scoped>
.service {
    padding: 20px;
    .title {
        border: 0;
        text-indent: 0;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .content {
        color: #adadad;
        text-indent: 1rem;
    }

}
</style>